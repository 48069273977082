var o = Object.defineProperty;
var l = (i, e, t) => e in i ? o(i, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : i[e] = t;
var n = (i, e, t) => l(i, typeof e != "symbol" ? e + "" : e, t);
import { toFriendly as a } from "@vgno/utils";
class d extends HTMLElement {
  constructor() {
    super();
    n(this, "interval", null);
    n(this, "timeElement", null);
  }
  update(t) {
    if (!this.timeElement)
      throw new Error("No time element found");
    const s = this.timeElement.dateTime;
    if (!s)
      throw new Error("No timestamp attribute found");
    const m = new Date(s).getTime(), r = a(
      m,
      void 0,
      t
    );
    this.timeElement.textContent = r;
  }
  connectedCallback() {
    this.timeElement = this.querySelector("time");
    const t = this.options;
    this.update(t), this.interval = setInterval(() => this.update(t), 1e3);
  }
  disconnectedCallback() {
    this.interval && clearInterval(this.interval);
  }
  get options() {
    const { options: t } = this.dataset;
    return t ? JSON.parse(t) : {};
  }
}
function u() {
  customElements.get("friendly-timestamp") || customElements.define("friendly-timestamp", d);
}
export {
  d as FriendlyTimestampElement,
  u as registerFriendlyTimestampElement
};
